import { forwardRef } from 'react';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

// type SVGIconProps = SVGProps<SVGSVGElement> & {
/**
 * The accessible label for the icon. This label will be visually hidden but announced to screen
 * reader users, similar to `alt` text for `img` tags.
 */
//   label?: string;
// };

const createSvgIcon = (viewBox, paths) =>
  forwardRef(({ label, ...props }, ref) => (
    <>
      <svg
        ref={ref}
        width="20"
        height="20"
        viewBox={viewBox}
        fill="transparent"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        {...props}
      >
        {paths}
      </svg>

      {!!label && <VisuallyHidden>{label}</VisuallyHidden>}
    </>
  ));

// type SVGIcon = ReturnType<typeof createSvgIcon>;

export { createSvgIcon };

// export type { SVGIconProps, SVGIcon };
