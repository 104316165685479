import { Tooltip } from '@zendeskgarden/react-tooltips';
import React, { cloneElement, forwardRef } from 'react';
import styled from 'styled-components';
import { InfoIcon } from '../icons/info';

const InfoTooltip = (props) => {
  const { tooltip, setUp = null, placement = 'auto' } = props;
  const tooltipIconProps = {};
  const tooltipProps = {};
  return (
    <IconTooltip.Root
      size={setUp ? 'medium' : 'large'}
      hasArrow={false}
      zIndex={10000}
      content={tooltip}
      placement={placement}
      {...tooltipProps}
    >
      <IconTooltip.Icon {...tooltipIconProps}>
        <InfoIcon {...props} width={14} height={14} />
      </IconTooltip.Icon>
    </IconTooltip.Root>
  );
};

const IconWrapper = forwardRef(({ children, ...props }, ref) => (
  <IconTooltipWrapper ref={ref} {...props}>
    {cloneElement(children, {
      style: { marginLeft: 1, ...(children.props?.style ?? {}) },
    })}
  </IconTooltipWrapper>
));

const IconTooltipWrapper = styled('div')`
  display: grid;
  place-items: center;
  margin-left: -1px;
`;

const IconTooltip = {
  Root: Tooltip,
  Icon: IconWrapper,
};

export { InfoTooltip };
