import React from 'react';
import { createSvgIcon } from '.';

const PDFIcon = createSvgIcon(
  '0 0 30 30',
  <>
    <path
      d="M10.3332 18.3477C10.1032 18.3477 9.94816 18.3702 9.86816 18.3927V19.8652C9.96316 19.8877 10.0819 19.8939 10.2457 19.8939C10.8444 19.8939 11.2132 19.5914 11.2132 19.0802C11.2132 18.6227 10.8957 18.3477 10.3332 18.3477ZM14.6919 18.3627C14.4419 18.3627 14.2794 18.3852 14.1832 18.4077V21.6702C14.2794 21.6927 14.4344 21.6927 14.5744 21.6927C15.5957 21.7002 16.2607 21.1377 16.2607 19.9477C16.2682 18.9102 15.6619 18.3627 14.6919 18.3627Z"
      fill="currentColor"
    />

    <path
      d="M17.5 2.5H7.5C6.83696 2.5 6.20107 2.76339 5.73223 3.23223C5.26339 3.70107 5 4.33696 5 5V25C5 25.663 5.26339 26.2989 5.73223 26.7678C6.20107 27.2366 6.83696 27.5 7.5 27.5H22.5C23.163 27.5 23.7989 27.2366 24.2678 26.7678C24.7366 26.2989 25 25.663 25 25V10L17.5 2.5ZM11.8725 20.2375C11.4862 20.6 10.9162 20.7625 10.2525 20.7625C10.1238 20.7639 9.99516 20.7564 9.8675 20.74V22.5225H8.75V17.6025C9.25432 17.5273 9.7639 17.493 10.2737 17.5C10.97 17.5 11.465 17.6325 11.7987 17.8988C12.1162 18.1513 12.3312 18.565 12.3312 19.0525C12.33 19.5425 12.1675 19.9563 11.8725 20.2375ZM16.6312 21.9313C16.1062 22.3675 15.3075 22.575 14.3313 22.575C13.7463 22.575 13.3325 22.5375 13.0512 22.5V17.6038C13.5558 17.5301 14.0652 17.4954 14.575 17.5C15.5212 17.5 16.1362 17.67 16.6162 18.0325C17.135 18.4175 17.46 19.0312 17.46 19.9125C17.46 20.8663 17.1112 21.525 16.6312 21.9313ZM21.25 18.4625H19.335V19.6013H21.125V20.5188H19.335V22.5237H18.2025V17.5375H21.25V18.4625ZM17.5 11.25H16.25V5L22.5 11.25H17.5Z"
      fill="currentColor"
    />
  </>
);

export { PDFIcon };
