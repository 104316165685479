import { Button } from '@zendeskgarden/react-buttons';
import { Alert, Title } from '@zendeskgarden/react-notifications';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { openZendeskModal, zafClient } from '../../api/ZendeskApiService';
import { useBackend } from '../../api/BackendApiService';
import Payment from '.';

export const UpgradeModal = () => {
  const { authData } = useBackend();
  const [upgradePlan, setUpgradePlan] = useState(false);
  useEffect(() => {
    zafClient.invoke('resize', { width: '755px', height: '330px' });
    zafClient.on('modal.close', () => {
      openZendeskModal();
    });
  }, []);

  if (upgradePlan) return <Payment upgradePlan={true} />;

  return (
    <div>
      <StyledSpan>You are about to exceed your admin limit</StyledSpan>
      <HorizontalLine></HorizontalLine>
      <br></br>
      <div style={{ marginLeft: '20px', display: 'grid' }}>
        <Alert type="warning">
          <Title>You have reached your agent tier limit</Title>
          Please upgrade your plan in order to add the newly selected agents
        </Alert>
        <br></br>
        <br></br>
        <DivContainer>
          <ExtraSpan>
            Current User: {authData?.subscription?.totalAdminCount + authData?.subscription?.totalUserCount}
          </ExtraSpan>
          <ExtraSpan>Newly Selected User: 1</ExtraSpan>
        </DivContainer>
        <br></br>
        <HorizontalLine></HorizontalLine>
        <br></br>
        <ButtonsContainer>
          <StyledButton onClick={() => setUpgradePlan(true)} isPrimary>
            Upgrade Plan
          </StyledButton>
        </ButtonsContainer>
      </div>
    </div>
  );
};

const StyledSpan = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #03363d;
  margin-left: 20px;
`;

const HorizontalLine = styled.hr`
  margin-top: 22px;
  height: 1px;
  border-top: 0.25px solid #eff0f1;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  font-weight: 700;
  font-size: 12px;
  height: 36px;
  float: right;
  margin: 0 5px;
  width: 100px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const ExtraSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #4c545c;
`;

const DivContainer = styled.div`
  display: flex;
  gap: 20px;
`;
