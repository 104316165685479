import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js';

function convertPdfFileToThumbnail(file, dimentions = { width: 273, height: 88 }) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          //@ts-expect-error Wrong typings
          const pdf = await getDocument({ data: reader.result }).promise;
          const page = await pdf.getPage(1);
          const defaultViewport = page.getViewport({ scale: 1 });

          const scaledViewport = page.getViewport({
            scale: dimentions.width / defaultViewport.width,
          });

          const canvas = document.createElement('canvas');
          canvas.width = scaledViewport.width;
          canvas.height = scaledViewport.height;

          await page.render({
            viewport: scaledViewport,
            canvasContext: canvas && canvas.getContext('2d') && canvas.getContext('2d'),
          }).promise;

          const img = canvas.toDataURL('image/png');

          resolve(img);
        } catch (e) {
          reject(e);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (e) {
      reject(e);
    }
  });
}

export { convertPdfFileToThumbnail };
