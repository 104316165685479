import { parsePhoneNumber } from 'libphonenumber-js';
import { formatIncompletePhoneNumber, getCountryCallingCode, parsePhoneNumberWithError } from 'libphonenumber-js/min';
import examples from 'libphonenumber-js/mobile/examples';

import metadata from 'libphonenumber-js/mobile/metadata';

function createFormatPhoneNumber(countryCode, phone) {
  if (!phone) return '+';

  if (phone === '+' || phone === '') return '+';

  function attachPlus(s) {
    return s.startsWith('+') ? s : `+${s}`;
  }

  const r = attachPlus(phone.replace(/[^\d]+/gi, ''));

  return countryCode ? formatIncompletePhoneNumber(r, countryCode) : formatIncompletePhoneNumber(r);
}

function countryCodeToPlaceholder(code) {
  return code ? `+${getCountryCallingCode(code)}` : '+';
}

function getMaxLengthOf(countryCode) {
  const phoneWithoutCode = examples[countryCode];
  const phone = `+${getCountryCallingCode(countryCode)}${phoneWithoutCode}`;

  // Exceptional case BG number has sample phone with one number less
  if (countryCode === 'BG') return parsePhoneNumber(phone).formatInternational().length + 1;

  return parsePhoneNumberWithError(phone).formatInternational().length;
}

const revertedMetadataCountries = Object.entries(metadata.countries).reduce((map, [countryCode, array]) => {
  if (array) {
    map.set(array, countryCode);
  }

  return map;
}, new Map());

function matchCountryCodeByMetadata(countryMetadataArray) {
  return revertedMetadataCountries.get(countryMetadataArray) ?? null;
}

function getNumericPlanMetadataFromAsYouType(asYouType) {
  //@ts-expect-error Private fields
  return asYouType?.metadata?.numberingPlan?.metadata ?? [];
}

function parseStringAndFormatInternational(maybePhone) {
  const phone = parsePhoneNumberWithError(maybePhone);

  return phone.formatInternational();
}

export {
  getNumericPlanMetadataFromAsYouType,
  createFormatPhoneNumber,
  countryCodeToPlaceholder,
  getMaxLengthOf,
  matchCountryCodeByMetadata,
  parseStringAndFormatInternational,
};
