import React from 'react';
import { createSvgIcon } from '.';

const PhoneIcon = createSvgIcon(
  '0 0 21 21',
  <path
    d="M16.1048 17.9874L18.2868 15.8033C18.5534 15.5367 18.7031 15.1799 18.7031 14.8025C18.7031 14.4252 18.5555 14.0684 18.2868 13.8018L15.9387 11.4495C15.6721 11.1829 15.3132 11.0332 14.9358 11.0332C14.5564 11.0332 14.2017 11.1809 13.933 11.4495L12.0935 13.287C11.1181 12.8684 10.232 12.267 9.48281 11.5151C8.72607 10.7625 8.1293 9.88271 7.70684 8.90654L9.54639 7.06904C9.81299 6.80244 9.9627 6.44561 9.9627 6.06826C9.96338 5.88214 9.92692 5.69775 9.85543 5.5259C9.78395 5.35405 9.67888 5.1982 9.54639 5.06748L7.19824 2.71318C6.93164 2.44658 6.57275 2.29687 6.19541 2.29687C5.81602 2.29687 5.46123 2.44453 5.19258 2.71318L3.01055 4.89521C2.55527 5.35049 2.29483 5.97803 2.29483 6.62197C2.29483 6.75527 2.30508 6.88447 2.32764 7.01572C2.77471 9.73096 4.22051 12.4257 6.39434 14.6016C8.57227 16.7754 11.267 18.2191 13.9843 18.6703C14.7554 18.7995 15.549 18.5411 16.1048 17.9874ZM14.2283 17.2163C11.8104 16.8164 9.39873 15.5162 7.44023 13.5577C5.48174 11.5992 4.18154 9.18955 3.78164 6.77168C3.73037 6.46816 3.83291 6.15644 4.05234 5.93496L6.19541 3.79189L8.46973 6.06416L6.00879 8.521L5.99033 8.53945L6.15439 8.98242C6.64821 10.3255 7.42818 11.5452 8.44024 12.5569C9.45229 13.5686 10.6722 14.3482 12.0155 14.8415L12.4585 15.0056L14.9338 12.5282L17.2081 14.8005L15.065 16.9436C14.8436 17.165 14.5318 17.2676 14.2283 17.2163Z"
    fill="currentColor"
  />
);

export { PhoneIcon };
