import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBackend } from '../../../api/BackendApiService';
import { convertPdfFileToThumbnail } from '../../../Utilities/pdf';
import { convertFileSizeBytesToHuman } from '../../../Utilities/size';
import { PDFIcon } from '../../../components/icons/pdf';
import { PDF_FILE_TYPE } from '../constants';

const imgPlaceholder = '/img-placeholder.png';
const docPlaceholder = '/doc-placeholder.png';
const greyBgImage =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM8/B8AAosBxERSgsYAAAAASUVORK5CYII=';

const MessageImg = (props) => {
  const { template, file } = props;
  const [src, setSrc] = useState(imgPlaceholder);
  useEffect(() => {
    setImageSrc();
  }, [file]);

  const setImageSrc = () => {
    template?.content?.isPdfMedia ? setSrc(docPlaceholder) : setSrc(imgPlaceholder);
    if (file && file.type === PDF_FILE_TYPE) {
      const createImgUrlFromPdfFx = convertPdfFileToThumbnail(file);
      createImgUrlFromPdfFx
        .then((res) => {
          setSrc(res);
        })
        .catch((error) => {
          console.log('Image url error : ', error);
          setSrc(docPlaceholder);
        });
    } else if (file && file.type !== PDF_FILE_TYPE) {
      fetch(URL.createObjectURL(file))
        .then((res) => {
          setSrc(res.url);
        })
        .catch((error) => {
          console.log('Image url error : ', error);
          setSrc(imgPlaceholder);
        });
    }
  };

  const onError = (e) => {
    e.target.src = greyBgImage;
  };

  return (
    <>
      <Img alt="" showTop={!!file && template?.content?.isPdf} src={src} onError={onError} />

      {!!file && template?.content?.isPdf && <PdfDetails file={file} />}
    </>
  );
};

const Img = styled.img`
  position: relative;

  height: 114px;
  width: 100%;

  object-fit: cover;
  object-position: ${(props) => props.showTop && 'top'};
  border-radius: 3px;
`;

const PdfDetails = () => {
  const { file } = useBackend();
  return (
    <PdfDetailsContainer>
      <PDFIcon color="#F34644" width={30} height={30} />

      <PdfDetailsTextContainer>
        <PdfDetailsTextFileName>filename</PdfDetailsTextFileName>

        <PdfDetailsTextFileSize>{convertFileSizeBytesToHuman(file.size)} PDF PDF</PdfDetailsTextFileSize>
      </PdfDetailsTextContainer>
    </PdfDetailsContainer>
  );
};

const PdfDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 8px;
  max-width: 272px;
  overflow: hidden;

  padding-top: 7.5px;
  padding-bottom: 7.5px;
  padding-left: 12px;
  padding-right: 12px;

  background: #f0f0f0;
  border-radius: 0px 0px 2px 2px;
`;

const PdfDetailsTextContainer = styled.div`
  overflow: hidden;
`;

const PdfDetailsTextFileName = styled.div`
  margin-bottom: 3px;

  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #000000;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PdfDetailsTextFileSize = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8c8c8c;
`;

export { MessageImg };
