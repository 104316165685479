import React, { useEffect } from 'react';
import { onModalClose, zafClient } from '../../../api/ZendeskApiService';
import styled from 'styled-components';
import { Button } from '@zendeskgarden/react-buttons';
import { Alert, Title } from '@zendeskgarden/react-notifications';
import { TIER_LIMIT } from '../constants';

function TierLimitUpgrade() {
  const queryParams = new URLSearchParams(window.location.search);
  const current = queryParams.get('currentTier');
  const selected = queryParams.get('selectedTier');
  useEffect(() => {
    zafClient.invoke('resize', { width: '650px', height: '380px' });
    zafClient.on('modal.close', () => {
      onModalClose().then((ticketSidebarClient) => {
        ticketSidebarClient.trigger('cancel');
      });
    });
  }, []);

  const confirmTierLimit = () => {
    zafClient.invoke('destroy');
    zafClient.on('modal.close', () => {
      onModalClose().then((ticketSidebarClient) => {
        ticketSidebarClient.trigger('confirm', selected);
      });
    });
  };

  const cancelTierLimit = () => {
    zafClient.invoke('destroy');
    zafClient.on('modal.close', () => {
      onModalClose().then((ticketSidebarClient) => {
        ticketSidebarClient.trigger('cancel');
      });
    });
  };

  return (
    <div>
      <StyledSpan>You are about to increase your tier limit</StyledSpan>
      <HorizontalLine></HorizontalLine>
      <br></br>
      <div style={{ marginLeft: '20px', display: 'grid' }}>
        <Alert type="warning">
          <Title>You are about to increase your tier limit</Title>
          Ensure you have selected your correct Meta Messaging Tier (Limit), as this will be synced and verified
          directly with Meta. Selecting a Tier (Messaging Limit) higher than what your actual limit is will result in
          you potentially exceeding your Messaging Limit which can result in failed campaigns as well as potentially
          impacting your Quality Rating of your channel.
        </Alert>
        <br></br>
        <br></br>
        {current && selected && (
          <div>
            <ExtraSpan>{`Current Tier: Tier ${
              TIER_LIMIT.findIndex((item) => item === parseInt(current)) + 1
            } (${current} contacts)`}</ExtraSpan>
            <br></br>
            <br></br>
            <ExtraSpan>{`Requested Tier: Tier ${
              TIER_LIMIT.findIndex((item) => item === parseInt(selected)) + 1
            } (${selected} contacts)`}</ExtraSpan>
          </div>
        )}
        <br></br>
        <ButtonsContainer>
          <StyledButton onClick={() => cancelTierLimit()}>Cancel</StyledButton>
          <StyledButton onClick={() => confirmTierLimit()} isPrimary>
            Confirm
          </StyledButton>
        </ButtonsContainer>
      </div>
    </div>
  );
}

const StyledSpan = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #03363d;
  margin-left: 20px;
`;

const HorizontalLine = styled.hr`
  margin-top: 22px;
  height: 1px;
  border-top: 0.25px solid #eff0f1;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  font-weight: 700;
  font-size: 12px;
  height: 36px;
  float: right;
  margin: 0 5px;
  width: 100px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const ExtraSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #4c545c;
`;

export default TierLimitUpgrade;
