import { Button } from '@zendeskgarden/react-buttons';
import { Field, Label, Toggle } from '@zendeskgarden/react-forms';
import { Inline } from '@zendeskgarden/react-loaders';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBackend } from '../../api/BackendApiService';
import Language from '../../pages/send-message/language';
import { MessagePreview } from './message-preview';
import { ToContractVars, getMessageType } from './template/templateAdapter';
import TemplateVars from './templateVars';
import Notification from '../../components/notification';
import Template from './template';
import Phone from './phone';
import { openPaymentModal, zafClient } from '../../api/ZendeskApiService';
import { Mixpanel } from '../../components/MixPanel';
import { MixPanelMessageSent, SUBCRIPTION_TYPE } from './constants';
import { Tag } from '@zendeskgarden/react-tags';
import CampaignName from '../../components/campaignName';
import BulkSending from './bulkSending';
import { calculateDaysBeforeTrial } from '../../Utilities/trialChecker';
import { TIER_LIMIT } from '../settings/constants';
import Channel from '../../components/channels';

const downArrow = '/asset/down-arrow.svg';
const upArrow = '/asset/up-arrow.svg';
let userCount = 0;

function SendMessageUi(props) {
  const { templateToSend } = props;
  const [diffDays, setDiffDays] = useState(null);
  const [bulkToggle, setBulkToggle] = useState(false);
  const [onlyHasOnePhone, setOnlyHasOnePhone] = useState(false);
  const [hasTicketId, sethasTicketId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [createTicket, setCreateTicket] = useState(false);
  const [bulkContactUpload, setBulkContactUpload] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const {
    authData,
    uploadFile,
    sendMessage,
    setNotification,
    notification,
    getVarUserFields,
    getTemplates,
    templates,
    clientData,
    getPreflightData,
    exportSampleContactFile,
    channels,
    getLanguages,
    setTemplates,
  } = useBackend();
  const [payload, setPayload] = useState({
    language: authData?.channel?.templateDefaultLanguage,
    template: null,
    vars: null,
    file: null,
    phone: null,
    tags: [],
  });
  const [notificationObj, setNotificationObj] = useState({});
  const [showPreview, setShowPreview] = useState(true);
  const [templateName, setTemplateName] = useState('');
  const [preflightData, setPreflightData] = useState([]);
  const [showWarningNotification, setShowWarningNotification] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(channels.find((item) => item.id === authData?.channel?.id));

  useEffect(() => {
    if (!clientData) return;
    if (clientData?.location === 'ticket_sidebar' && !isValidTicket()) return;
    setOnlyHasOnePhone(clientData?.phones?.length === 1);
    sethasTicketId(clientData?.ticketId);
    setCreateTicket(authData?.auth?.defaultAllowCreateTicketValue);
    setDiffDays(calculateDaysBeforeTrial(authData, diffDays));
    onFieldValueChange('language', authData?.channel?.templateDefaultLanguage);
    setSelectedLanguage(authData?.channel?.templateDefaultLanguage);
  }, []);

  useEffect(() => {
    if (templateToSend) {
      if (selectedLanguage !== templateToSend.language) {
        onChangeLanguage(templateToSend.language);
      } else {
        onFieldValueChange('language', selectedLanguage);
      }
      setTemplateDataFromProps();
    }
  }, [templateToSend]);

  useEffect(() => {
    if (templateToSend && templateToSend?.language === selectedLanguage) {
      setTemplateDataFromProps();
    } else {
      setSelectedTemplate(null);
      setTemplateName('');
      onFieldValueChange('template', null);
    }
  }, [templates, selectedLanguage]);

  useEffect(() => {
    onChangeLanguage(selectedChannel?.templateDefaultLanguage)
  }, [selectedChannel])

  // setSelectedTemplate passed as props
  const setTemplateDataFromProps = () => {
    const templateData = getMessageType(templateToSend);
    if (selectedTemplate?.name !== templateData?.label) {
      getVarUserFields();
      const template = templates?.find((item) => item.name === templateData?.label);
      setSelectedTemplate(template);
      setTemplateName(template?.name);
      if (template) onFieldValueChange('template', templateData);
    }
  };

  const isValidTicket = () => {
    let title;
    let description;

    if (clientData?.status === 'closed') {
      title = 'Ticket closed';
      description = 'You cannot send message from the closed ticket';
    } else if (clientData?.channel !== 'whatsapp' && clientData?.channel !== 'web') {
      title = 'Unsuported ticket';
      description = 'Proactive sidebar supports message sending for only Whatsapp and Web tickets';
    }

    if (title && description) {
      const notificationObject = {
        title,
        description,
        mode: 'success',
        hideClose: true,
      };

      setNotification(notificationObject);
      return false;
    }

    return true;
  };

  // To set all fields value
  const onFieldValueChange = (field, value) => {
    const requestPayload = { ...payload };
    requestPayload[field] = value;
    if (field === 'template') {
      requestPayload['vars'] = null;
      requestPayload['file'] = null;
      requestPayload['language'] = value?.original?.language;
    }
    setPayload(requestPayload);
    if (field === 'sourceFile' && !!value?.length) {
      fetchPreflightData(value);
      delete requestPayload?.tags;
    }
    if (field === 'tags' && !!value?.length) delete requestPayload?.sourceFile;
    setPayload(requestPayload);
    setCanSubmit(isValidForm(requestPayload));
  };

  const isValidForm = (requestPayload) => {
    if (!requestPayload.language || !requestPayload.template) return false;
    if (!!requestPayload?.template?.vars?.length) {
      if (!requestPayload.vars) return false;
      if (!bulkContactUpload) {
        const varStatus = Object.keys(requestPayload?.template?.vars).every((key) => {
          const value = requestPayload?.template?.vars[key]?.value;
          const isUrl = requestPayload?.template?.vars[key]?.isUrl;
          const urlPrefix = requestPayload?.template?.vars[key]?.urlPrefix;
          if (isUrl && value && value.length === urlPrefix?.length) {
            return false;
          }
          if (!value || value === '') {
            return false;
          }
          return true;
        });

        if (!varStatus) return false;
      }
    }
    if (clientData?.location === 'ticket_sidebar' || clientData?.location === 'new_ticket_sidebar') return true;
    if (bulkContactUpload && !requestPayload?.sourceFile) return false;
    if (requestPayload.template?.content.media && !requestPayload.file) return false;
    if (bulkToggle && !bulkContactUpload && !requestPayload?.tags?.length) return false;
    if (!bulkToggle && !requestPayload.phone) return false;
    if (bulkContactUpload && !preflightData.length) return false;

    return true;
  };

  const onChangeBulkToggle = (status) => {
    if (!selectedTemplate) {
      const notificationObject = {
        title: 'Template not selected',
        description: 'Please select template before using bulk sending',
        button: { text: 'Select Template' },
        mode: 'warning',
        hideClose: false,
      };
      setNotification(notificationObject);
      return;
    }
    setBulkToggle(status);

    if (status) {
      onFieldValueChange('phone', null);
      setCreateTicket(false);
    } else onFieldValueChange('tags', null);
  };

  const onSendMessage = () => {
    setLoading(true);
    let data = {
      locale: payload?.language,
      templateId: selectedTemplate?.id || payload?.template?.value,
      userId: clientData.id,
      ...ToContractVars(payload.template.original.variables, payload.template.vars),
    };

    if (payload.tags) data.tags = payload.tags;
    if (payload.sourceFile) data.sourceFile = payload.sourceFile;
    if (payload.phone) data.phone = payload.phone;
    if (clientData?.location === 'ticket_sidebar' || clientData?.location === 'new_ticket_sidebar') {
      if (!bulkToggle && !payload.phone) data.phone = clientData?.phones[0]?.value;
    }
    if (clientData?.channel !== 'whatsapp' && authData?.auth?.allowCreateTicket) data.createTicket = createTicket;

    if (clientData?.ticketId) data.ticketId = clientData.ticketId;
    if (bulkToggle) data.name = templateName;

    if (payload?.template?.content.media) {
      uploadFile(payload.file)
        .then((res) => {
          const url = res.data.data.publicUrl;
          payload?.template?.content.isPdfMedia
            ? (data.pdfUrl = new URL(url).href)
            : (data.imageUrl = new URL(url).href);

          sendMesageApiCall(data);
        })
        .catch((error) => {
          setLoading(false);
          const errorMsg = error?.response?.data;
          const notificationObject = {
            title: "Couldn't upload Image",
            description: `${errorMsg.message} (${authData?.auth?.domain})`,
            button: { text: 'Retry' },
            mode: 'error',
            hideClose: false,
          };
          setNotification(notificationObject);
          const requestPayload = { ...payload };
          Object.keys(requestPayload).map((val) => (requestPayload[val] = null));
          setPayload(requestPayload);
        });
    } else {
      sendMesageApiCall(data);
    }
  };

  const sendMesageApiCall = (data) => {
    setLoading(true);
    sendMessage(data, selectedChannel?.id?.toString())
      .then((res) => {
        Mixpanel.track(MixPanelMessageSent, {
          Domain: authData.auth.domain,
          SentBy: clientData.user.email,
          AppLocation: clientData.location,
          TemplateType: payload.template.original.category,
          MessageType: data.phone && !data.tags?.length ? 'Single Send' : 'Bulk Send',
          UserCount: data.phone && !data.tags?.length ? null : userCount,
        });
        const notificationObject = {
          title: 'Template sent',
          description: 'Template message successfully sent to the user/s',
          button: { text: 'Send Another', onClick: 'yes' },
          mode: 'success',
          hideClose: true,
        };
        setLoading(false);
        setNotification(notificationObject);
        if (res?.data?.communication?.ticketId) zafClient.invoke('routeTo', 'ticket', res.data.communication.ticketId);
      })
      .catch((error) => {
        setLoading(false);
        let title = 'Couldn’t send message';
        let description = 'Check your internet connection and try again';
        let mode = 'error';
        const errorData = error.responseJSON;
        let button = { text: 'Retry' };
        if (errorData?.code === 'WarningException') {
          title = 'Warning';
          if (errorData?.data?.userCount === 0) {
            description = 'The tag you selected is not tied to any users';
            button.text = 'Back';
            mode = 'success';
            const notificationObject = {
              title: title,
              description: description,
              button: button,
              mode: mode,
              hideClose: true,
              showNotification: true,
            };
            setCanSubmit(true);
            setNotificationObj(notificationObject);
            return;
          } else {
            description = errorData?.message;
            button.text = 'Send Anyway';
            mode = 'success';
            button.onClick = () => {
              data.force = true;
              userCount = errorData?.data?.userCount;
              sendMesageApiCall(data);
              setNotification(null);
            };
          }
        } else if (errorData?.code === 'TierLimitExceeded') {
          title = 'Tier Limit Exceeded';
          description = errorData.message;
          button.text = 'Back';
          button.onClick = () => {
            setNotification(null);
          };
        } else if (error.status === 403 || error.status === 401) {
          description = errorData?.message;
          button = null;
        } else {
          description = errorData?.message;
        }
        const notificationObject = {
          title: title,
          description: description,
          button: button,
          mode: mode,
          hideClose: false,
        };
        setNotification(notificationObject);
      });
  };

  const onChangeCreateTicket = (e) => {
    setCreateTicket(e);
  };

  const onSubscriptionButtonClick = () => {
    if (!authData.subscription) return openPaymentModal();
    if (authData.subscription?.type !== SUBCRIPTION_TYPE.invoice && authData?.subscription?.status !== 'paid')
      return openPaymentModal();
    if (authData?.subscription?.type === SUBCRIPTION_TYPE.invoice) return openPaymentModal(SUBCRIPTION_TYPE.invoice);
    return openPaymentModal(true);
  };

  const displayBuyNowOrChangePlan = () => {
    if (!authData?.subscription) return <>Buy Now</>;
    if (authData?.subscription?.type !== SUBCRIPTION_TYPE.invoice && authData?.subscription?.status !== 'paid')
      return <>Buy Now</>;
    if (authData?.subscription?.type === SUBCRIPTION_TYPE.invoice) return <>Show Plan</>;
    return <>Change Plan</>;
  };

  // language selection event
  const onChangeLanguage = (item) => {
    setSelectedLanguage(item);
    getTemplates(item, selectedChannel?.channelId);
    onFieldValueChange('language', item);
  };

  // template selection event
  const onChangeTemplate = (item) => {
    const selectedTemplateData = getMessageType(item);
    if (selectedTemplateData?.value) {
      setSelectedTemplate(item);
      setTemplateName(item?.name);
      onFieldValueChange('template', selectedTemplateData);
    }
    getVarUserFields();
  };

  const onChannelChange = (item) => {
    setSelectedChannel(item);
    setTemplates(null);
    getLanguages(item.channelId);
  };

  const onChangeTemplateName = (value) => {
    const name = value;
    const specialCharsPattern = /[^\w\s]/g;
    const nameWithoutSpecialChar = name.replace(specialCharsPattern, '');
    setTemplateName(nameWithoutSpecialChar);
    onFieldValueChange('name', nameWithoutSpecialChar);
  };

  const fetchPreflightData = (fileUrl) => {
    setLoading(true);
    setPreflightData([]);
    const data = {
      sourceFile: fileUrl,
      templateId: selectedTemplate?.id,
      channelId: authData?.channel?.channelId,
    };
    getPreflightData(data)
      .then((res) => {
        setLoading(false);
        const { totalRecords, templateVariablesCount, fileVariablesCount, errors } = res?.data?.preflightResult;
        const preflightObj = [
          { status: 'success', message: `${totalRecords} Contacts` },
          { status: 'success', message: `${templateVariablesCount} Variables found` },
          {
            status: templateVariablesCount === fileVariablesCount ? 'success' : 'error',
            message: `${fileVariablesCount}/${templateVariablesCount} Variables matched`,
          },
        ];
        preflightObj.push({
          status: totalRecords > authData?.channel?.dailyLimit ? 'error' : 'success',
          message: `WhatsApp Tier ${TIER_LIMIT.findIndex((item) => item === authData?.channel?.dailyLimit) + 1} (Max ${
            authData?.channel?.dailyLimit
          } Contacts)`,
        });
        errors?.map((error) => {
          preflightObj.push({ status: 'error', message: error });
        });
        setPreflightData(preflightObj);
        totalRecords > authData?.channel?.dailyLimit && setShowWarningNotification(true);
        if (
          fileVariablesCount !== templateVariablesCount ||
          !!errors?.length ||
          totalRecords > authData?.channel?.dailyLimit
        )
          return setCanSubmit(false);

        if (payload?.template?.content.media && !payload.file) return setCanSubmit(false);

        setCanSubmit(true);
      })
      .catch((error) => {
        setLoading(false);
        let title = 'Couldn’t get preflight data';
        let description = 'Check your internet connection and try again';
        let mode = 'error';
        const errorData = error.responseJSON;
        let button = { text: 'Retry' };
        if (error.status === 403 || error.status === 401) {
          description = errorData?.message;
          button = null;
        } else {
          description = errorData?.message;
        }
        const notificationObject = {
          title: title,
          description: description,
          button: button,
          mode: mode,
          hideClose: false,
        };
        setNotification(notificationObject);
      });
  };

  const downloadSampleFile = async () => {
    const payload = {
      templateId: selectedTemplate?.id,
      channelId: authData?.channel?.channelId,
    };
    const downloadFilePath = await exportSampleContactFile(payload);
    if (downloadFilePath) {
      const downloadURL = `${process.env.REACT_APP_API_KEY.split('/').splice(0, 3).join('/')}/${downloadFilePath}`;
      const a = document.createElement('a');
      a.href = downloadURL;
      a.download = `export.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  if (notification || notificationObj?.showNotification)
    return (
      <CenterPageContainer>
        <Notification
          onClose={() => {
            setNotificationObj({});
            setCanSubmit(true);
          }}
          {...notificationObj}
        />
      </CenterPageContainer>
    );

  return (
    <Container location={clientData}>
      {!authData?.auth?.fullAccess && (
        <div className="flex justify-between">
          <StyleSpan>New Message</StyleSpan>
          <StyledButton isPrimary onClick={() => onSubscriptionButtonClick()}>
            {displayBuyNowOrChangePlan()}
          </StyledButton>
          {authData?.subscription?.type === SUBCRIPTION_TYPE.invoice &&
            (authData?.subscription?.status === 'sent' || authData?.subscription?.status === 'open') && (
              <Tag hue="yellow" style={{ float: 'right', marginTop: '4px' }} size="small" isPill>
                <span style={{ fontWeight: '600' }}>Payment Due</span>
              </Tag>
            )}
        </div>
      )}

      {channels?.filter((ele) => ele.isSetup).length > 1 && (
        <Channel
          onChannelChange={onChannelChange}
          selectedChannel={selectedChannel}
          lable="Select Channel"
          tooltip="Disclaimer: Multi-Channel is a BETA feature. Due to SunCo API limitations, tickets should be closed before switching channels. If multiple tickets are open across channels, replies may not go to the correct ticket. Chatbots or answer bots may also affect functionality."
          showBetaTag
        />
      )}

      <Language
        selectedLanguage={selectedLanguage}
        onChangeLanguage={(item) => onChangeLanguage(item)}
        label="Select Template Language"
        color="#03363d"
      />

      <Template
        selectedTemplate={selectedTemplate}
        onChangeTemplate={(value) => onChangeTemplate(value)}
        clientData={clientData}
      />
      {showPreview && (
        <>
          {!bulkContactUpload && (
            <TemplateVars
              template={payload.template}
              onChange={(field, value) => onFieldValueChange(field, value)}
              bulkEnabled={bulkToggle}
              clientData={clientData}
            />
          )}
          {payload.template && (
            <MessagePreview
              file={payload.file}
              template={payload.template}
              onChange={(field, value) => onFieldValueChange(field, value)}
            />
          )}
        </>
      )}
      {payload.template && (
        <div className="flex gap-3 items-center">
          <hr className="flex-1 text-[#E5E7EB]" />
          <div className="flex gap-2 cursor-pointer" onClick={() => setShowPreview(!showPreview)}>
            <img src={showPreview ? upArrow : downArrow} alt="DownArrow" />
            <span className="text-sm text-[#68737D]">{showPreview ? 'Hide Preview' : 'Show Preview'}</span>
          </div>
          <hr className="flex-1 text-[#E5E7EB]" />
        </div>
      )}
      {clientData?.user?.role === 'admin' && (
        <>
          {(authData?.auth?.fullAccess || authData?.bulkEnabled) && (
            <Field>
              <Toggle onChange={(event) => onChangeBulkToggle(event.target.checked)} defaultChecked={bulkToggle}>
                <PhoneBulkToggleLabel>Bulk Message</PhoneBulkToggleLabel>
              </Toggle>
            </Field>
          )}
        </>
      )}
      {payload.template && bulkToggle && (
        <CampaignName
          label="Campaign Name"
          templateName={templateName}
          onChangeTemplateName={onChangeTemplateName}
          tooltip="Enter campaign name (optional)"
          fontSize="12px"
          color="#03363d"
        />
      )}
      {bulkToggle ? (
        <BulkSending
          valueTags={payload.tags}
          onChange={(field, value) => onFieldValueChange(field, value)}
          bulkSelectedItem={(option) => {
            option === 'tags' ? setBulkContactUpload(false) : setBulkContactUpload(true);
            onFieldValueChange('tags', []);
            onFieldValueChange('sourceFile', null);
          }}
          preflightData={preflightData}
          downloadSampleFile={() => downloadSampleFile()}
          showWarningNotification={showWarningNotification}
        />
      ) : (
        <div>
          {clientData?.channel !== 'whatsapp' && authData?.auth?.allowCreateTicket && (
            <Field style={{ marginBottom: '15px' }}>
              <Toggle
                defaultChecked={authData?.auth?.defaultAllowCreateTicketValue}
                onChange={(event) => onChangeCreateTicket(event.target.checked)}
              >
                <PhoneBulkToggleLabel>Create Ticket</PhoneBulkToggleLabel>
              </Toggle>
            </Field>
          )}

          {(!onlyHasOnePhone || !hasTicketId) && (
            <Phone
              value={payload.phone}
              clientData={clientData}
              onChange={(field, value) => onFieldValueChange(field, value)}
            />
          )}
        </div>
      )}
      <SendButtonContainer>
        <SendButton isPrimary disabled={!canSubmit || loading} onClick={() => onSendMessage()}>
          {loading && <Spinner />}

          <Text hidden={loading}>Send</Text>
        </SendButton>
      </SendButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  gap: 23px;
  padding: ${(props) => (props?.location?.location === 'top_bar' ? '20px' : '0px')};
  margin-right: ${(props) => (props?.location?.location !== 'top_bar' ? '10px' : '0px')};
`;

const SendButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyleSpan = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #03363d;
  width: 50%;
`;

const StyledButton = styled(Button)`
  padding: 0 12px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 10px;
  height: 25px;
  float: right;
  margin: 0 5px;
  width: 80px;
  justify-self: end;
`;

const PhoneBulkToggleLabel = styled(Label)`
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  display: flex;
  height: 23px;
  color: #03363d;
  font-weight: 700;
`;

const Spinner = styled(Inline)`
  position: absolute;
`;

const Text = styled.span`
  visibility: ${(props) => props?.hidden && 'hidden'};
`;

const SendButton = styled(Button)`
  padding: 10px 20px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const CenterPageContainer = styled.div`
  display: grid;
  place-items: center;
  flex: 1;
  height: 100%;
`;

export default SendMessageUi;
