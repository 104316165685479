import React from 'react';
import { createSvgIcon } from '.';

const LaunchIcon = createSvgIcon(
  '0 0 22 21',
  <path
    d="M16.7361 14.4861H7.88889V5.63889H12.3125V4.375H7.88889C7.18743 4.375 6.625 4.94375 6.625 5.63889V14.4861C6.625 15.1812 7.18743 15.75 7.88889 15.75H16.7361C17.4312 15.75 18 15.1812 18 14.4861V10.0625H16.7361V14.4861ZM13.5764 4.375V5.63889H15.8451L9.63306 11.8509L10.5241 12.7419L16.7361 6.52993V8.79861H18V4.375H13.5764Z"
    fill="currentColor"
  />
);

export { LaunchIcon };
