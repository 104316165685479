import React from 'react';
import { createSvgIcon } from '.';
const InfoIcon = createSvgIcon(
  '0 0 16 16',
  <>
    <path
      d="M7.5 15.5C11.366 15.5 14.5 12.366 14.5 8.5C14.5 4.63401 11.366 1.5 7.5 1.5C3.63401 1.5 0.5 4.63401 0.5 8.5C0.5 12.366 3.63401 15.5 7.5 15.5Z"
      stroke="currentColor"
      strokeWidth="0.8125"
    />

    <path d="M7.5 12.5V8Z" fill="currentColor" />

    <path d="M7.5 12.5V8" stroke="currentColor" strokeWidth="0.8125" strokeLinecap="round" />

    <path
      d="M7.5 6C8.05228 6 8.5 5.55228 8.5 5C8.5 4.44772 8.05228 4 7.5 4C6.94772 4 6.5 4.44772 6.5 5C6.5 5.55228 6.94772 6 7.5 6Z"
      fill="currentColor"
    />
  </>
);

export { InfoIcon };
