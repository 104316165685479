import ZAFClient from 'zendesk_app_framework_sdk';
import { SUBCRIPTION_TYPE } from '../pages/send-message/constants';
export const zafClient = ZAFClient.init();

const filterPhonesIdentity = (userIdentites) => {
  const test = userIdentites
    .filter(({ type }) => type === 'phone_number')
    .map(({ value }) => ({
      label: `${value}`,
      value: `+${value.replaceAll(/[^\d]/g, '')}`,
    }));
  return test;
};

export const checkWhatsappAllowedUser = async () => {
  const {
    currentUser: { id },
  } = await zafClient.get('currentUser');
  const settings = {
    type: 'GET',
    url: '/api/v2/users/' + id,
  };
  const response = await zafClient.request(settings);
  if (response.user.role_type === 1 || response.user.only_private_comments) return false;
  return true;
};

export const getUserData = async () => {
  const response = await zafClient.get(['user.id', 'user.identities', 'user.externalId']);
  let phones = filterPhonesIdentity(response['user.identities']);
  if (response['user.externalId']) {
    phones.push({
      label: response['user.externalId'],
      value: response['user.externalId'],
    });
  }
  phones = phones.filter((value, index, self) => {
    const idx = self.findIndex((t) => t.value === value.value);
    return index === idx;
  });

  const { currentUser } = await zafClient.get('currentUser');
  const { location } = await zafClient.context();
  const { version } = await zafClient.metadata();
  return {
    id: response['user.id'],
    phones: phones,
    user: currentUser,
    location: location,
    version: version,
  };
};

export const getUserPhonesFx = async () => {
  const response = await zafClient.get([
    'ticket.requester.identities',
    'ticket.requester.id',
    'ticket.id',
    'ticket.via.channel',
    'ticket.requester.externalId',
    'ticket.status',
  ]);

  let phones = filterPhonesIdentity(response['ticket.requester.identities']);
  if (response['ticket.requester.externalId']) {
    phones.push({
      label: response['ticket.requester.externalId'],
      value: response['ticket.requester.externalId'],
    });
  }
  phones = phones.filter((value, index, self) => {
    const idx = self.findIndex((t) => t.value === value.value);
    return index === idx;
  });
  const { currentUser } = await zafClient.get('currentUser');
  const { location } = await zafClient.context();
  const { version } = await zafClient.metadata();
  return {
    location: location,
    id: response['ticket.requester.id'],
    ticketId: response['ticket.id'],
    phones: phones,
    channel: response['ticket.via.channel'],
    status: response['ticket.status'],
    user: currentUser,
    version: version,
  };
};

export const no_user = async () => {
  const { currentUser } = await zafClient.get('currentUser');
  const { location, account } = await zafClient.context();
  const { version } = await zafClient.metadata();
  return {
    id: 0,
    phones: [],
    user: currentUser,
    location: location,
    version: version,
    account: account,
  };
};

export const shared = {
  resizeFx: (params) => {
    zafClient.invoke('resize', params);
  },
};

export const openZendeskModal = () => {
  const modalData = zafClient
    .invoke('instances.create', {
      location: 'modal',
      url: `${window.location.origin}/zd-agent-modal`,
    })
    .then(function (modalContext) {
      zafClient.instance(modalContext['instances.create'][0].instanceGuid);
    });
  return modalData;
};

export const openUpgradeModal = () => {
  const modalData = zafClient
    .invoke('instances.create', {
      location: 'modal',
      url: `${window.location.origin}/zd-upgrade-modal`,
    })
    .then(function (modalContext) {
      zafClient.instance(modalContext['instances.create'][0].instanceGuid);
    });
  return modalData;
};

export const tierLimitUpgradeModal = async (params) => {
  const { location } = await zafClient.context();
  localStorage.setItem('lastVisitedPage', location);
  const modalData = zafClient
    .invoke('instances.create', {
      location: 'modal',
      url: `${window.location.origin}/zd-tier-limit-modal?currentTier=${params.currentTier}&selectedTier=${params.selectedTier}`,
    })
    .then(function (modalContext) {
      zafClient.instance(modalContext['instances.create'][0].instanceGuid);
    });
  return modalData;
};

export const openPaymentModal = async (upgrade = null) => {
  let url = `${window.location.origin}/zd-payment-modal`;
  if (upgrade === SUBCRIPTION_TYPE.invoice) url = `${window.location.origin}/zd-invoice-modal`;
  else if (upgrade) url = `${window.location.origin}/zd-change-plan-modal`;
  const { location } = await zafClient.context();
  localStorage.setItem('lastVisitedPage', location);
  const modalData = zafClient
    .invoke('instances.create', {
      location: 'modal',
      url: url,
    })
    .then(function (modalContext) {
      zafClient.instance(modalContext['instances.create'][0].instanceGuid);
    });
  return modalData;
};

export const onModalClose = () => {
  const ticketSidebarClientPromise = zafClient?.get('instances').then((instancesData) => {
    var instances = instancesData.instances;
    let location = 'user_sidebar';
    const lastVisitedPage = localStorage.getItem('lastVisitedPage');
    if (lastVisitedPage) {
      location = lastVisitedPage;
    }
    for (var instanceGuid in instances) {
      if (instances[instanceGuid].location === location) {
        return zafClient.instance(instanceGuid);
      }
    }
  });
  return ticketSidebarClientPromise;
};
