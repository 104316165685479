import styled from 'styled-components';
import { Dots } from '@zendeskgarden/react-loaders';
import { PALETTE } from '@zendeskgarden/react-theming';

const Loader = () => {
  return (
    <Container>
      <Dots size={32} color={PALETTE.green[600]} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default Loader;
