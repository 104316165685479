import mixpanel from 'mixpanel-browser';

let env_check = process.env.REACT_APP_NODE_ENV === 'production';
let mixPanelToken = process.env.REACT_APP_MIX_PANEL_TOKEN;
if (env_check) {
  // debug:true remove this parameter before going into production;
  mixpanel.init(mixPanelToken, { track_pageview: false });
}
let actions = {
  // mixpanel.identify is used to set distinct id for each event.
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  // An alias is a new value that will be interpreted by Mixpanel as an existing value.
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  // track used to logged event in mix panel
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  // people let you enrich events with properties about the users that performed those events.
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
