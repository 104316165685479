import { Dropdown, Field, Item, Label, Menu, Select } from '@zendeskgarden/react-dropdowns';
import { Label as InputLabel, Field as InputField, Input } from '@zendeskgarden/react-forms';
import {
  AsYouType,
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  createFormatPhoneNumber,
  getNumericPlanMetadataFromAsYouType,
  matchCountryCodeByMetadata,
  parseStringAndFormatInternational,
} from '../../../Utilities/phoneFormater';
import { MAX_INTERNATIONAL_PHONE_LENGTH } from '../constants';

function Phone(props) {
  const { clientData, onChange } = props;
  const [selectedPhone, setSelectedPhone] = useState();
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [phones, setPhones] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [inputNumber, setInputNumber] = useState();
  const [validationStatus, setValidationStatus] = useState();
  useEffect(() => {
    fetchPhones();
    fetchCountries();
  }, []);

  const fetchPhones = () => {
    const items = clientData?.phones.map((phone) => {
      const label = phone.label.includes('+')
        ? parseStringAndFormatInternational(phone.label)
        : parseStringAndFormatInternational('+' + phone.label);
      return { label, value: phone.value };
    });
    setPhones(items);
  };

  const fetchCountries = () => {
    const countries = getCountries();

    setCountryCodes(countries);
  };

  const onPhoneSelect = (item) => {
    setSelectedPhone(item);
    onChange('phone', item);
  };

  const onCountrySelect = (item) => {
    const countryCode = '+' + getCountryCallingCode(item);
    setSelectedCountry(item);
    setInputNumber(countryCode);
    onChange('phone', null);
  };

  const onInputNumberChange = (value) => {
    if (value.length === 0) {
      setInputNumber('+');
    }
    const countryCode = getCountryFromInput(value);
    setValidationStatus(isValidPhoneNumber(value) ? 'success' : 'error');
    setInputNumber(createFormatPhoneNumber(countryCode, value));

    if (isValidPhoneNumber(value) && isPhoneReachedMaxLength(createFormatPhoneNumber(countryCode, value))) {
      onChange('phone', value);
    } else {
      onChange('phone', null);
    }
  };

  const getCountryFromInput = (phone) => {
    const asYouType = new AsYouType();

    asYouType.input(phone);
    const country =
      asYouType.getCountry() ?? matchCountryCodeByMetadata(getNumericPlanMetadataFromAsYouType(asYouType));
    if (!country) {
      const code = asYouType.getCallingCode();

      const matchingCountriesArray = asYouType.metadata.metadata.country_calling_codes[code ?? 1];
      setSelectedCountry(matchingCountriesArray?.length > 0 && matchingCountriesArray[0]);
      return matchingCountriesArray?.length > 0 && matchingCountriesArray[0];
    }
    setSelectedCountry(country);
    return country;
  };

  const isPhoneReachedMaxLength = (value) => {
    if (selectedCountry === 'DE') {
      return !!(selectedCountry && validatePhoneNumberLength(value, selectedCountry) === undefined);
    }
    return value?.length <= MAX_INTERNATIONAL_PHONE_LENGTH;
  };

  return (
    <>
      {clientData.phones.length > 0 ? (
        <Dropdown
          selectedItem={selectedPhone}
          onSelect={(item) => onPhoneSelect(item)}
          downshiftProps={{ itemToString: (item) => item && item }}
        >
          <Field>
            <PhoneSelectLabel>Phone:</PhoneSelectLabel>

            <Select isCompact disabled={false}>
              {selectedPhone}
            </Select>
          </Field>

          <Menu>
            {phones ? (
              phones.map((option) => (
                <Item key={option.value} value={option.label}>
                  {option.label}
                </Item>
              ))
            ) : (
              <Item disabled>Loading...</Item>
            )}
          </Menu>
        </Dropdown>
      ) : (
        <PhoneInputContainer>
          <PhoneInputContainerLabel>Add new phone number below:</PhoneInputContainerLabel>

          <Dropdown onSelect={(item) => onCountrySelect(item)} selectedItem={selectedCountry}>
            <Field>
              <Select isCompact disabled={false}>
                {selectedCountry && (
                  <CountryValueContainer>
                    <>
                      <img
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountry}.svg`}
                        alt=""
                        loading="lazy"
                        height={13}
                      />

                      <span>{selectedCountry}</span>
                    </>
                  </CountryValueContainer>
                )}
              </Select>
            </Field>

            <Menu maxHeight="200px" style={{ width: 175 }}>
              {countryCodes.map((country) => (
                <CountryListItem key={country} value={country}>
                  <>
                    <img
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
                      alt=""
                      loading="lazy"
                      height={13}
                    />

                    <span>{country}</span>

                    <CountryCallingCode>+{getCountryCallingCode(country)}</CountryCallingCode>
                  </>
                </CountryListItem>
              ))}
            </Menu>
          </Dropdown>

          <Input
            isCompact
            type="tel"
            value={inputNumber}
            onChange={(event) => onInputNumberChange(event.target.value)}
            onPaste={(event) => onInputNumberChange(event.target.value)}
            placeholder="+1"
            defaultValue="+1"
            validation={isPhoneReachedMaxLength(inputNumber) ? validationStatus : undefined}
            maxLength={MAX_INTERNATIONAL_PHONE_LENGTH}
          />
        </PhoneInputContainer>
      )}
    </>
  );
}
const PhoneSelectLabel = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  //because Select.Value already have margin-top: 4px;
  // margin-bottom: 7px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: #03363d;
`;

const PhoneInputContainerLabel = styled(InputLabel)`
  font-size: 12px;
  line-height: 14px;
  grid-column: 1 / 3;
  color: #03363d;
  font-weight: 700;
`;

const PhoneInputContainer = styled(InputField)`
  display: grid;
  gap: 10px;
  grid-template-columns: 100px 1fr;
`;

const CountryValueContainer = styled.div`
  display: grid;
  place-items: center;
  align-items: center;
  justify-content: center;
  grid-template-columns: 20px 1fr;
  gap: 10px;

  line-height: 15px;
`;

const CountryListItem = styled(Item)`
  display: grid;
  place-items: center;
  grid-template-columns: 20px 2ch 1fr;
  gap: 10px;

  line-height: 15px;
`;

const CountryCallingCode = styled.span`
  width: 100%;
`;

export default Phone;
